window.addEventListener('DOMContentLoaded', event => {

    // Collapse responsive navbar when toggler is visible
    const navbarToggler = document.body.querySelector('.navbar-toggler');
    const responsiveNavItems = [].slice.call(
        document.querySelectorAll('#mainNav .nav-link')
    );
    responsiveNavItems.map(function (responsiveNavItem) {
        responsiveNavItem.addEventListener('click', () => {
            if (window.getComputedStyle(navbarToggler).display !== 'none') {
                navbarToggler.click();
            }
        });
    });

    class CountUp {
        constructor(triggerEl, counterEl) {
        const counter = document.querySelector(counterEl)
        const trigger = document.querySelector(triggerEl)
        let num = 0
      
        const countUp = () => {
          if (num <= counter.dataset.stop)
            ++num
            counter.textContent = num
        }
        
        const observer = new IntersectionObserver((el) => {
          if (el[0].isIntersecting) {
            const interval = setInterval(() => {
              (num < counter.dataset.stop) ? countUp() : clearInterval(interval)
            }, counter.dataset.speed)
          }
        }, { threshold: [0] })
      
        observer.observe(trigger)
        }
      }
      
      // Initialize any number of counters:
      new CountUp('#counter-row', '#program-partners-count')
      new CountUp('#counter-row', '#average-savings-count')
      new CountUp('#counter-row', '#heroes-helped-count')
      new CountUp('#counter-row', '#years-helping-count')

// Get all the divs with class servicesSection__col
const serviceCols = document.querySelectorAll('.servicesSection__col');

// Loop through each div
serviceCols.forEach((serviceCol) => {
  // Get the image inside the div
  const img = serviceCol.querySelector('img');
  const src = img.getAttribute('src');
  const hoverSrc = src.replace('.png', '-hover.png');

  // Preload the hover image
  const hoverImage = new Image();
  hoverImage.src = hoverSrc;

  // Add mouseover and mouseout event listeners to the div
  serviceCol.addEventListener('mouseover', function() {
    img.setAttribute('src', hoverSrc);
  });

  serviceCol.addEventListener('mouseout', function() {
    img.setAttribute('src', src);
  });
});


});

if (window.location.pathname.includes('about')) {

const swiper = new Swiper('.swiper', {
  spaceBetween: 40,
  breakpoints: {
    768: {
      slidesPerView: 3
    }
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});

}

if (window.location.pathname.includes('eligibility')) {
  // Your JavaScript code for the "eligibility" page goes here
  // This code will only run if the current page has the slug "eligibility"
  window.addEventListener('load', function() {
    setTimeout(function() {
      const myModal = new bootstrap.Modal('.modal');
        myModal.show();
    }, 5000);
  });
}